<template>
  <!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <!-- header-title -->
      <div class="header-column header-title">
        생활연수 신청내역
      </div>
      <!-- //header-title -->
      <!-- header-util -->
      <div class="header-column header-util right">
        <div class="util util-search">
          <a href="" class="util-actions util-actions-search">
            <i class="icon-search"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-facility">
    <!-- main-header -->
    <div class="main-header main-header-visual">
      <!-- ㅡmain-header-nav -->
      <div class="header-snb">
        <nav class="snb">
          <ul class="snb-list">
            <li class="snb-item">
              <button :class="{'is-active': reserveVenue === '연수원'}" @click="reserveVenue = '연수원'">연수원</button>
            </li>
            <li class="snb-item">
              <button :class="{'is-active': reserveVenue === '체육시설'}" @click="reserveVenue = '체육시설'">체육시설</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <!-- //main-header -->
    <!-- main-content -->
    <div class="main-content" v-if="reserveVenue === '연수원'">
      <section class="content-section-wrap">
        <div class="section-contents side-pd">
          <ul class="facility-status">
            <li class="facility-status-item">
              <p class="sub-text text-silver">전체 {{ paging.totalCount }}건</p>
            </li>

            <div v-if="items && items.length === 0" class="search-result">
              <div class="result-empty">
                <img src="../../../../assets/lxp/images/common/img_empty.png">
                <p class="text">검색결과가 없어요</p>
              </div>
            </div>

            <li v-for="(item) in items" :key="item.trnctAplySn" class="facility-status-item">
              <div class="titles">
                <h5 class="title">{{ item.trnctNm }}</h5>
                <p class="status is-applying">{{ item.stbyYn == 'Y' ? `대기(순번 : ${item.stbySeq + 2})` : '신청완료'}}</p>
              </div>
              <div class="desc">
<!--                <p class="text bolder">203동 1201호</p>-->
                <p class="text"><span class="date">신청기간&nbsp;</span>{{ item.rsvtAplyDd }}-{{ item.rsvtAplyDdEnd }}</p>
                <p class="text"><span class="date">신청일시&nbsp;</span>{{ item.regDt }}</p>
              </div>
              <div class="buttons" v-if="checkThreeDay(item)">
                <button class="status-button" @click="detailTrcntAply(item)">상세 ・ 수정</button>
                <button class="status-button" @click="openCancelPop(item)">취소</button>
              </div>
              <div v-else-if="new Date() > new Date(item.rsvtAplyDd) "  class="item-row">
                <span>-</span>
              </div>
              <div class="buttons" v-else>
                <button class="status-button" @click="alertThreeday">상세 ・ 수정</button>
                <button class="status-button" @click="alertThreeday">취소</button>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section class="content-section-wrap">
        <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
      </section>
    </div>
    <!-- //main-content -->

    <!-- main2-content -->
    <div class="main-content" v-if="reserveVenue === '체육시설'">
      <section class="content-section-wrap">
        <div class="section-contents side-pd">
          <ul class="facility-status">
            <li class="facility-status-item">
              <p class="sub-text text-silver">전체 {{ paging2.totalCount }}건</p>
            </li>

            <div v-if="items2 && items2.length === 0" class="search-result">
              <div class="result-empty">
                <img src="../../../../assets/lxp/images/common/img_empty.png">
                <p class="text">검색결과가 없어요</p>
              </div>
            </div>

            <li v-for="(item) in items2" :key="item.fcltRsvtAplySn" class="facility-status-item">
              <div class="titles">
                <h5 class="title">{{ item.fcltDivNm }}</h5>
                <p class="status is-applying">{{ item.allocSttYn == 'Y' ? '배정완료' : '신청중' }}</p>
              </div>
              <div class="desc">
                <!--                <p class="text bolder">203동 1201호</p>-->
                <p class="text"><span class="date">신청기간&nbsp;</span>{{ item.aplyYmd }} - {{ getSportTime(item)}}</p>
                <p class="text"><span class="date">신청일시&nbsp;</span>{{ item.regDt }}</p>
              </div>
              <div class="buttons">
                <button class="status-button" @click="detailFcltRsvtAply(item)">상세 ・ 수정</button>
                <button class="status-button" @click="cancelTrnctAply(item)">취소</button>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section class="content-section-wrap">
        <CommonPaginationFront :paging="paging2" :page-func="pagingFunc2"/>
      </section>
    </div>
    <!-- //main2-content -->
  </main>
  <!-- end::kb-main -->

  <div class="kb-alert-container" :class="{'is-active' : data.isCancelPop}">
    <div class="kb-alert">
      <div class="kb-alert-contents">
        <div class="title-area">
          <strong class="kb-alert-title">취소 신청</strong>
        </div>
        <div class="contents-area">
          <p>취소사유</p>
          <textarea rows="10" class="kb-form-control" placeholder="취소사유를 입력해 주세요." v-model="updateTrnctAply.cancelDesc"></textarea>
        </div>
        <div class="footer-area">
          <p class="text text-muted">※ 입소 3영업일 전까지는 취소가 가능하며 이후 취소시에는 연수원 담당자 앞 개별 요청 해주셔야 합니다.</p>
        </div>
      </div>
      <div class="kb-alert-actions">
        <button class="kb-btn-alert" @click="closeCancelPop"><span class="text">닫기</span></button>
        <button class="kb-btn-alert" @click="cancelTrnctAply"><span class="text">취소 신청</span></button>
      </div>
    </div>
  </div>

  <div class="kb-alert-container" :class="{'is-active': data.isCancelConfirmPop}">
    <div class="kb-alert">
      <div class="kb-alert-contents pt-5">
        <strong class="kb-alert-title">신청취소</strong>
        <p class="kb-alert-text text-center">신청이 정상 취소되었습니다. <br/>감사합니다.</p>
      </div>
      <div class="kb-alert-actions">
        <button class="kb-btn-alert" type="button" @click="data.isCancelConfirmPop = false"><span class="text">확인</span></button>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, reactive, onMounted, watch} from 'vue';
import {
  initPaging,
  lengthCheck,
  getItems,
  getPaging,
  setParamsByQueryString,
  initProgressBar,
  isSuccess,
  getPreviousBusinessDay, dateToDateFormat
} from "@/assets/js/util";
import {useStore} from "vuex";
import {
  ACT_CANCEL_FCLT_RSVT_APLY,
  ACT_CANCEL_TRNCT_PLAY,
  ACT_GET_FCLT_APLY,
  ACT_GET_TRNCT_APLY,
  ACT_GET_TRNCT_OPER_YMD_CHECK, ACT_INIT_FCLT_APLY_MOBILE_INFO,
  ACT_INIT_TRNCT_APLY_MOBILE_INFO, ACT_SET_FCLT_APLY_MOBILE_INFO,
  ACT_SET_TRNCT_APLY_MOBILE_INFO,
  ACT_UPDATE_FCLT_RSVT_APLY,
  ACT_UPDATE_TRNCT_APLY
} from "@/store/modules/trnct/trnct";
import CommonPaginationFront from "@/components/CommonPaginationFront";

import {useRoute, useRouter} from 'vue-router';
import {useAlert} from "@/assets/js/modules/common/alert";

export default {
  name: 'DailyMobileApplyHistory',
  components: {CommonPaginationFront},
  setup() {

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const {showConfirm, showMessage} = useAlert();

    const reserveVenue = ref('연수원');
    const showVenues = ref(false);
    const showCalendar = ref(false);
    const filterVenue = ref('');
    const filterDate = ref('');


    const paging = ref(initPaging(route));
    const items = ref([{}]);

    const paging2 = ref(initPaging(route));
    const items2 = ref([{}]);

    const search = reactive({
      //trnctNm: null, //연수원 명
      //prsnBrcYn: null, //개인 부점 구분 Y는 개인 N은 부점
      rsvtAplyDdStart: null, //예약 신청일
      rsvtAplyDdEnd: null,
    });

    const data = reactive({
      isPersonalPop : false,
      isStorePop : false,
      isCancelPop : false,
      isCancelConfirmPop: false,
      isSportPop: false,
      display : null
    })

    const usePeridOption = ref([]);

    const toggles = reactive({
      periodOpen: false
    });

    const selectUsePeriod = (usePeriod) => {
      toggles.periodOpen = false;
      updateTrnctAply.usePerid = usePeriod.value;
      data.display = usePeriod.label;
    }

    const openPeriod = () => {
      if(toggles.periodOpen === true){
        toggles.periodOpen = false;
      } else{
        toggles.periodOpen = true;
      }
    }



    const updateTrnctAply = reactive({
      trnctAplySn : null,
      prsnBrcYn : null,
      aplcntId : null,
      aplcntJbgd: null,
      aplcntNm: null,
      accDeptNm: null,
      rsvtAplyDd: null,
      rsvtAplyDdEnd: null,
      trnctNm: null,
      roomTy: null,
      emerCt: null,
      carNo : null,
      mblTelno: null,
      useRoomCnt : null,
      mlePeopl: null,
      femPeopl: null,
      usePerid: null,
      aplyRsn: null,
      dmndCn: null,
      aplyDtl : [
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null, //동반자 명
        },
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null, //동반자 명
        },
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null, //동반자 명
        },
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null, //동반자 명
        },
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null, //동반자 명
        }
      ],
      cancelDesc: null,
      stbyYn : 'N'
    })


    const updateFcltRsvtAply = reactive({
      fcltRsvtAplySn : null,
      aplyYmd : null,
      aplcntId : null,
      aplcntNm : null,
      aplcntDeptNm : null,
      grpNm : null,
      aplyPeopl : null,
      part01RsvtYn : null,
      part02RsvtYn : null,
      part03RsvtYn : null,
      part04RsvtYn : null,
      part05RsvtYn : null,
      regDt : null,
      mdfcnDt : null,
      aplcntTelno : null,
      aplcntTelno2 : null
    });


    const getTrcntAply = () => {
      console.log('연수원 검색')
      store.dispatch(`trnct/${ACT_GET_TRNCT_APLY}`, {
        rsvtAplyDdStart: search.rsvtAplyDdStart,
        rsvtAplyDdEnd: search.rsvtAplyDdEnd,
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize,
      }).then(res => {
        console.log("연수원 검색완료");
        if (lengthCheck(res)) {
          console.log(res);
          items.value = getItems(res);
          paging.value = getPaging(res);
        } else {
          items.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      });
    }

    const getFcltAply = () => {
      console.log('체육시설 검색');
      store.dispatch(`trnct/${ACT_GET_FCLT_APLY}`, {
        rsvtAplyDdStart: search.rsvtAplyDdStart,
        rsvtAplyDdEnd: search.rsvtAplyDdEnd,
        pageNo: paging2.value.pageNo,
        pageSize: paging2.value.pageSize,
      }).then(res => {
        console.log("체육시설 검색완료");
        console.log(res);
        if (lengthCheck(res)) {
          console.log(res);
          items2.value = getItems(res);
          paging2.value = getPaging(res);
        } else {
          items2.value = [];
          paging2.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      });
    }

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      router.push({query: query});
    };

    const pagingFunc2 = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging2.value.pageSize) {
        query.pageSize = pageSize;
      }
      router.push({query: query});
    };

    watch(() => route.query, () => {
      console.log(route.name);
      if(reserveVenue.value === '연수원'){
        setParamsByQueryString(route, 'DailyHistory', paging, search, true);
        getTrcntAply();
      }else{
        setParamsByQueryString(route, 'DailyHistory', paging2, search, true);
        getFcltAply();
      }

      initProgressBar();
    });

    watch(() => reserveVenue.value, () => {
      console.log('reserveVenue', reserveVenue.value)
      if(reserveVenue.value === "연수원"){
        pagingFunc2(1,10);
        getTrcntAply();
      }else if(reserveVenue.value === "체육시설"){
        pagingFunc2(1,10);
        getFcltAply();
      }
    });


    onMounted(() => {
      getTrcntAply()
    });

    const closePop = (pop) => {
      clearParams();
      if(pop == 'personal'){
        data.isPersonalPop = false;
      }else if(pop == 'personal'){
        data.isStorePop = false;
      }else{
        updateTrnctAply.cancelDesc = null;
        data.isCancelPop = false;
      }
    }

    const detailTrcntAply = (item) => {
      updateTrnctAply.trnctAplySn = item.trnctAplySn;
      updateTrnctAply.prsnBrcYn = item.prsnBrcYn;
      updateTrnctAply.aplcntId = item.aplcntId;
      updateTrnctAply.aplcntJbgd= item.aplcntJbgd;
      updateTrnctAply.aplcntNm= item.aplcntNm;
      updateTrnctAply.accDeptNm= item.accDeptNm;
      updateTrnctAply.aplcntDeptNm= item.aplcntDeptNm;
      updateTrnctAply.rsvtAplyDd= item.rsvtAplyDd;
      updateTrnctAply.trnctNm= item.trnctNm;
      updateTrnctAply.roomTy= item.roomTy;
      updateTrnctAply.room= item.roomTy;
      updateTrnctAply.emerCt= item.emerCt;
      updateTrnctAply.carNo = item.carNo;
      updateTrnctAply.mblTelno= item.mblTelno;
      updateTrnctAply.useRoomCnt = item.useRoomCnt;
      updateTrnctAply.mlePeopl= item.mlePeopl;
      updateTrnctAply.femPeopl= item.femPeopl;
      updateTrnctAply.usePerid= item.usePerid;
      updateTrnctAply.aplyRsn= item.aplyRsn;
      updateTrnctAply.dmndCn= item.dmndCn;
      updateTrnctAply.trnctMstSn = item.trnctMstSn;

      updateTrnctAply.aplyPeopl = item.aplyPeopl;
      updateTrnctAply.stbyYn = item.stbyYn;

      for(let i = 0 ; i < item.aplyDtl.length ;i++){
        updateTrnctAply.aplyDtl[i].cpnnNm = item.aplyDtl[i].cpnnNm;
        updateTrnctAply.aplyDtl[i].aplcntRel = item.aplyDtl[i].aplcntRel;
      }

      store.commit(`trnct/${ACT_INIT_TRNCT_APLY_MOBILE_INFO}`);
      store.commit(`trnct/${ACT_SET_TRNCT_APLY_MOBILE_INFO}`, updateTrnctAply);

      console.log("chk", updateTrnctAply)

      router.push('/daily/apply/detail');
    }

    const detailFcltRsvtAply = (item) => {
      updateFcltRsvtAply.fcltRsvtAplySn = item.fcltRsvtAplySn;
      updateFcltRsvtAply.aplyYmd = item.aplyYmd;
      updateFcltRsvtAply.aplcntId = item.aplcntId;
      updateFcltRsvtAply.aplcntNm = item.aplcntNm;
      updateFcltRsvtAply.aplcntDeptNm = item.aplcntDeptNm;
      updateFcltRsvtAply.grpDivCdDcd= item.grpDivCdDcd;
      updateFcltRsvtAply.grpDivNm= item.grpDivNm;
      updateFcltRsvtAply.grpNm = item.grpNm;
      updateFcltRsvtAply.aplyPeopl = item.aplyPeopl;
      updateFcltRsvtAply.part01RsvtYn = item.part01RsvtYn;
      updateFcltRsvtAply.part02RsvtYn = item.part02RsvtYn;
      updateFcltRsvtAply.part03RsvtYn = item.part03RsvtYn;
      updateFcltRsvtAply.part04RsvtYn = item.part04RsvtYn;
      updateFcltRsvtAply.part05RsvtYn = item.part05RsvtYn;
      //updateFcltRsvtAply.regDt = item.regDt;
      //updateFcltRsvtAply.mdfcnDt = item.mdfcnDt;
      updateFcltRsvtAply.aplcntTelno = item.aplcntTelno;
      updateFcltRsvtAply.aplcntTelno2 = item.aplcntTelno2;
      updateFcltRsvtAply.brdt = item.brdt;
      updateFcltRsvtAply.brdt2 = item.brdt2;
      updateFcltRsvtAply.aplyYmd2 = item.aplyYmd2;
      updateFcltRsvtAply.aplcntId2 = item.aplcntId2;
      updateFcltRsvtAply.aplcntNm2 = item.aplcntNm2;
      updateFcltRsvtAply.aplcntDeptNm2 = item.aplcntDeptNm2;

      store.commit(`trnct/${ACT_INIT_FCLT_APLY_MOBILE_INFO}`);
      store.commit(`trnct/${ACT_SET_FCLT_APLY_MOBILE_INFO}`, updateFcltRsvtAply);

      router.push('/daily/apply/sports/detail');
    }

    const cancelFcltRsvtAplyPop = () =>{
      data.isSportPop = false;
    }

    const closeCancelPop = () => {
      data.isCancelPop =false;
    }

    const openCancelPop = (item) => {
      updateTrnctAply.trnctAplySn = item.trnctAplySn;
      updateTrnctAply.stbyYn = item.stbyYn;
      data.isCancelPop = true;
    }

    const cancelTrnctAply = async (item) => {
      console.log('cancelTrnctAply');

      showConfirm({
          text: '취소 하시겠습니까?',
          callback: async () => 
        {

          if(reserveVenue.value === '연수원'){
            await store.dispatch(`trnct/${ACT_CANCEL_TRNCT_PLAY}`, updateTrnctAply).then(res => {
              if (isSuccess(res)) {
                data.isCancelPop = false;
                updateTrnctAply.cancelDesc = null;
                getTrcntAply();
              }
            }).catch((e) => {console.error(e);})
          }else if(reserveVenue.value === '체육시설'){

              if(item.fcltRsvtAplySn != undefined){
                updateFcltRsvtAply.fcltRsvtAplySn = item.fcltRsvtAplySn;
              }
              await store.dispatch(`trnct/${ACT_CANCEL_FCLT_RSVT_APLY}`, updateFcltRsvtAply).then(res => {
                if (isSuccess(res)) {
                  data.isCancelConfirmPop = true;
                  getFcltAply();
                }
              }).catch((e) => {console.error(e);})
          }    
        }
      });

    }

    const getTrnctOperYmdCheck = async (item) => {
      await store.dispatch(`trnct/${ACT_GET_TRNCT_OPER_YMD_CHECK}`,{
        trnctMstSn : item.trnctMstSn,
        rsvtAplyDd : item.rsvtAplyDd,
        prsnBrcYn : item.prsnBrcYn,
        roomTy : item.roomTy
      }).then(res => {
        if(lengthCheck(res)){
          console.log('getTrnctOperYmdCheck...',res);
          usePeridOption.value = [];
          for(let i = 0 ; i < res.items.length ;i++){
            usePeridOption.value.push(res.items[i]);
          }


          let selectOption = usePeridOption.value.filter( (v) => {
            return v.value == item.usePerid
          })

          if(selectOption.length != 0){
            data.display = selectOption[0].label;
          }
        }
      }).catch((e) => {console.error(e);})
    }

    const setUpdateTrnctAply = async() => {

      if(updateTrnctAply.prsnBrcYn == 'N'){

        if(updateTrnctAply.mlePeopl == null || updateTrnctAply.mlePeopl == ''){
          updateTrnctAply.mlePeopl = 0;
        }

        if(updateTrnctAply.femPeopl == null || updateTrnctAply.femPeopl == ''){
          updateTrnctAply.femPeopl = 0;
        }
        updateTrnctAply.aplyPeopl = parseInt(updateTrnctAply.mlePeopl) + parseInt(updateTrnctAply.femPeopl);
      }

      await store.dispatch(`trnct/${ACT_UPDATE_TRNCT_APLY}`, updateTrnctAply).then(res => {
        if (isSuccess(res)) {
          clearParams();
          data.isPersonalPop = false;
          data.isStorePop = false;
          getTrcntAply();
        }
      }).catch((e) => {console.error(e);})
    }

    const setUpdateFcltRsvtAply = async () => {
      await store.dispatch(`trnct/${ACT_UPDATE_FCLT_RSVT_APLY}`, updateFcltRsvtAply).then(res => {
        if (isSuccess(res)) {
          data.isSportPop = false;
          getFcltAply();
        }
      }).catch((e) => {console.error(e);})
    }


    const getDayOfWeek = (_date) => {
      const week = ['일', '월', '화', '수', '목', '금', '토'];
      const dayOfWeek = week[new Date(_date).getDay()];
      return dayOfWeek;
    }

    const getSportTime = (item) => {
      if(item.part01RsvtYn == 'Y'){
        return "08:00 - 10:00 (1회차)"
      }else if(item.part02RsvtYn == 'Y'){
        return "10:00 - 12:00 (2회차)"
      }else if(item.part03RsvtYn == 'Y'){
        return "12:00 - 14:00 (3회차)"
      }else if(item.part04RsvtYn == 'Y'){
        return "14:00 - 16:00 (4회차)"
      }else if(item.part05RsvtYn == 'Y'){
        return "16:00 - 18:00 (5회차)"
      }
    }

    watch(updateTrnctAply.aplyDtl, () => {
      updateTrnctAply.aplyPeopl = updateTrnctAply.aplyDtl.filter( (v)=> {return ( v.cpnnNm != null && v.cpnnNm != '') } ).length
    });

    watch(() => updateTrnctAply.roomTy, () => {
      toggles.periodOpen = false;
      data.display = null;
      updateTrnctAply.usePerid = null;
      if(data.isPersonalPop == true )
      getTrnctOperYmdCheck(updateTrnctAply);
    })
    
    const clearParams = () => {
          updateTrnctAply.trnctAplySn = null;
          updateTrnctAply.prsnBrcYn = null;
          updateTrnctAply.aplcntId = null;
          updateTrnctAply.aplcntJbgd= null;
          updateTrnctAply.aplcntNm= null;
          updateTrnctAply.accDeptNm= null;
          updateTrnctAply.rsvtAplyDd= null;
          updateTrnctAply.trnctNm= null;
          updateTrnctAply.roomTy= null;
          updateTrnctAply.emerCt= null;
          updateTrnctAply.carNo = null;
          updateTrnctAply.mblTelno= null;
          updateTrnctAply.useRoomCnt = null;
          updateTrnctAply.mlePeopl= null;
          updateTrnctAply.femPeopl= null;
          updateTrnctAply.usePerid= null;
          updateTrnctAply.aplyRsn= null;
          updateTrnctAply.dmndCn= null;
          updateTrnctAply.stby='N';
          updateTrnctAply.aplyDtl = [
            {
              aplcntRel : null, //신청자 관계
              cpnnNm : null //동반자 명
            },
            {
              aplcntRel : null, //신청자 관계
              cpnnNm : null //동반자 명
            },
            {
              aplcntRel : null, //신청자 관계
              cpnnNm : null //동반자 명
            },
            {
              aplcntRel : null, //신청자 관계
              cpnnNm : null //동반자 명
            },
            {
              aplcntRel : null, //신청자 관계
              cpnnNm : null //동반자 명
            }
          ]
    }
    const alertThreeday = () =>{
      showMessage('3영업일 이내 수정/취소 하실 수 없습니다.');
      return;
    }
    const checkThreeDay = (item) => {

      if(item.rsvtAplyDd != undefined) {
        const masTime = new Date(item.rsvtAplyDd);

        // 당일포함 3영업일이므로 days는 2로 지정
        let limitDay = getPreviousBusinessDay(masTime, 2, 'yyyy-MM-dd');
        const todayTime = dateToDateFormat(new Date(), 'yyyy-MM-dd');

        return todayTime < limitDay;
      }
      //
      // const masTime = new Date(item.rsvtAplyDd);
      // const todayTime = new Date();
      // const diff = masTime - todayTime;
      //
      // const diffDay = Math.floor(diff / (1000*60*60*24));
      // console.log(diffDay);
      //
      // if(diffDay <= 2){
      //   return false;
      // }
      // return true;
    }

    return {
      updateFcltRsvtAply,
      getFcltAply,
      openCancelPop,
      closeCancelPop,
      cancelTrnctAply,
      closePop,
      clearParams,
      setUpdateTrnctAply,
      getTrnctOperYmdCheck,
      selectUsePeriod,
      usePeridOption,
      toggles,
      openPeriod,
      getDayOfWeek,
      updateTrnctAply,
      data,
      detailTrcntAply,
      reserveVenue,
      showVenues,
      showCalendar,
      filterVenue,
      filterDate,
      getTrcntAply,
      items,
      paging,
      pagingFunc,
      paging2,
      items2,
      pagingFunc2,
      getSportTime,
      detailFcltRsvtAply,
      cancelFcltRsvtAplyPop,
      setUpdateFcltRsvtAply,
      checkThreeDay,
      alertThreeday
    }
  }
};
</script>

<style>
.title-area{
  width:100%;
  padding-bttom:5px;
  border-bottom: 1px solid #e8e8e8;
  text-align:center;
  margin-bottom:5px;
}

.contents-area{
  width:100%;
  padding:10px;
  background-color: var(--kb-background-brown);
  border-radius: var(--kb-border-radius);
  margin-bottom:5px;
}

.contents-area p{
  padding:5px;
  border-bottom: 1px solid #e8e8e8;
}

textarea{
  background-color: var(--kb-background-brown);
  border-radius: var(--kb-border-radius) !important;
  padding: 5px !important;
}
</style>
